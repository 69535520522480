// helpers
import Storage from 'shared/helpers/storage';
import NFA from 'shared/helpers/analytics/NFAnalytics';

// constants
import { SEARCHES_KEY } from 'shared/components/GlobalSearch/constants';
import { SEARCH_EVENTS } from 'shared/helpers/analytics/constants/search';

// defs
import { ISuggestion, SearchPerformedEventParams } from 'shared/store/globalSearch/types';

export interface ITrackingProps {
  url: string;
  searchType?: string;
  internalSearchTerm: string;
  typedSearchTerm?: string;
  suggestionType?: string;
  suggestionSubtitle?: string;
}

interface AutoSuggestLoadParams {
  suggestions: ISuggestion[];
  searchTerm: string;
}

export const generateTrackingURLForSearch = ({
  url,
  searchType,
  internalSearchTerm,
  typedSearchTerm,
  suggestionType,
  suggestionSubtitle,
}: ITrackingProps) => {
  const param = new URLSearchParams();
  param.set('searchType', searchType);
  param.set('internalSearchTerm', internalSearchTerm);

  if (typedSearchTerm) {
    param.set('typedSearchTerm', typedSearchTerm);
  }

  if (suggestionType) {
    param.set('suggestionType', suggestionType);
  }

  if (suggestionSubtitle) {
    param.set('subTitle', suggestionSubtitle);
  }

  if (url.indexOf('?') > 1) {
    return `${url}&${param.toString()}`;
  }

  return `${url}?${param.toString()}`;
};

export const getRecentSearches = (): ISuggestion[] => {
  return Storage.getItem<ISuggestion[]>(SEARCHES_KEY) || [];
};

export const addToRecentSearch = (searchInfo: ISuggestion, maxRecentSearches: number) => {
  const searches = getRecentSearches();

  const newSearches = [searchInfo, ...searches]
    .filter((value: ISuggestion, idx: number, self) => {
      return (
        self.some((loopValue, lIdx) => {
          return (
            idx > lIdx &&
            loopValue.suggestionWord.toLowerCase() === value.suggestionWord.toLowerCase()
          );
        }) === false
      );
    })
    .slice(0, maxRecentSearches);

  Storage.setItem(SEARCHES_KEY, newSearches);
};

export const clearSearches = () => Storage.removeItem(SEARCHES_KEY);

export const isConfigValid = (maxItems: number) => maxItems > 0;

export const trackSearchCall = (eventParams: SearchPerformedEventParams) => {
  NFA.track({
    event: SEARCH_EVENTS.AUTOSUGGEST_CLICK,
    data: eventParams,
  });
};

export const trackAutosuggest = ({ suggestions, searchTerm }: AutoSuggestLoadParams) => {
  const transformedSuggestions = suggestions.map((sugg, index) => {
    const obj = {
      position: index,
      q: sugg.suggestionWord,
      url: sugg.url,
      type: sugg.type,
      id: sugg.id,
    };
    return obj;
  });

  NFA.track({
    event: SEARCH_EVENTS.AUTOSUGGEST_LOAD,
    data: {
      searchTerm: searchTerm,
      autosuggestCount: suggestions.length,
      autosuggestList: {
        page_search_term: searchTerm,
        suggestions: transformedSuggestions,
      },
    },
  });
};
