// lib
import { createContext, useContext } from 'react';

// types
import { ISearchInfo, ISuggestionProps } from 'shared/store/globalSearch/types';

const EMPTY_METHOD = () => {};

interface IContextProps {
  triggerSearch: boolean;
  currentIndex: number;
  list: ISearchInfo[] | ISuggestionProps[];
  triggerSearchEvent: (a: boolean) => void;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const initialValue: IContextProps = {
  triggerSearch: false,
  currentIndex: -1,
  list: [],
  triggerSearchEvent: EMPTY_METHOD,
  setCurrentIndex: EMPTY_METHOD,
};

const keyboardEventContext = createContext(initialValue);

export const useKeyboardEventContext = () => useContext(keyboardEventContext);
export default keyboardEventContext;
