// lib
import get from 'lodash/get';

// helpers
import { getRecentSearches } from 'shared/components/GlobalSearch/helpers';

// constants
import {
  DESKTOP_MAX_TRENDING_SEARCHES,
  DESKTOP_MAX_RECENT_SEARCHES,
} from 'shared/components/GlobalSearch/constants';

// selectors
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

export const getDesktopConfigValues = (state: any) => {
  let maxTrendingItems = DESKTOP_MAX_TRENDING_SEARCHES;
  let maxRecentSearches = DESKTOP_MAX_RECENT_SEARCHES;
  let currentRecentSearchLength = 0; // default value
  let algo = '';
  const searches = getRecentSearches();
  currentRecentSearchLength = searches && searches.length;

  try {
    const config = getRemoteConfig(state, 'globalSearch');
    const desktopConfig = get(config, 'desktop');
    maxTrendingItems = get(
      desktopConfig,
      'max_trending_suggestions',
      DESKTOP_MAX_TRENDING_SEARCHES
    );
    maxRecentSearches = get(desktopConfig, 'max_recent_suggestions', DESKTOP_MAX_RECENT_SEARCHES);
    algo = get(desktopConfig, 'popular_or_trending_api_algo');
  } catch (error) {
    // TODO: add an exception in sentry here
  }

  const currentRecentAndTrendingLength = currentRecentSearchLength + maxTrendingItems;

  const data = {
    maxTrendingItems,
    maxRecentSearches,
    searches,
    currentRecentSearchLength,
    currentRecentAndTrendingLength,
    algo,
  };

  return data;
};

export const getMwebConfigValues = (state: any) =>
  getRemoteConfig(state, 'globalSearch')?.mobile?.popular_or_trending_api_algo || '';
