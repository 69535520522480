export interface ISuggestion {
  url: string;
  suggestionWord: string;
  suggestionSubtitle?: string;
  type?: string;
  id?: string;
  sku?: string;
  image_base?: string;
  image?: string;
  subtext?: string;
  image_url?: string;
}

export interface ISuggestionWidget {
  suggestions: ISuggestion[];
  message: string;
  term?: string;
}

export interface IWidgetTerm {
  url: string;
  id: string;
  type: string;
  suggestionWord: string;
}

export interface IWidget {
  results: IWidgetTerm[];
  message: string;
}

export type IGlobalSearchState = Readonly<{
  isAutoCompleteFetching: boolean;
  isAutoCompleteFetchingError: boolean;
  isSuggestionFailed: boolean;
  popularOrTrending: ISuggestionWidget;
  autocompleteResults: ISuggestionWidget;
  widgets: IWidget;
}>;

export interface IAction {
  type: string;
  payload: ISuggestionWidget;
}

export enum ICON_TYPE {
  SEARCH = 'magnify',
  TRENDING = 'trending',
  RECENT = 'clock',
}

export interface ISearchInfo {
  url: string;
  suggestionWord: string;
  type: string;
  id: string;
}

export interface ISuggestionProps {
  url: string;
  title: string;
  term: string;
  type: string;
  subTitle?: string;
  icon: ICON_TYPE;
  isRipple: boolean;
  indexValue: number;
}

export interface SearchPerformedEventParams {
  typedSearchTerm: string;
  searchTerm: string;
  searchType: string;
  clickPosition?: number;
  clickMetadata?: Record<string, string | number>;
}
