// libs
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// services
import {
  getDwebSearchSuggestions,
  getMwebSearchSuggestions,
  getAutoSuggestData,
} from 'shared/api/globalSearch';

// selectors
import {
  getAutoCompleteSearchTerm,
  getAutoCompleteResults,
} from 'shared/store/globalSearch/selectors';

// actions
import { ActionTypes } from './actions';

// constants
const DWEB_SEARCH_SESSION_VARIABLE = 'dweb_search_suggestions';
const MWEB_SEARCH_SESSION_VARIABLE = 'mweb_search_suggestions';

// for mweb only
export const callWidgetService = () => {
  return (dispatch: any) => {
    dispatch({ type: ActionTypes.FETCH_SUGGESTIONS_DATA_PROGRESS });

    // check if the data is available in session
    if (window.sessionStorage !== undefined) {
      const sessionData = window.sessionStorage.getItem(MWEB_SEARCH_SESSION_VARIABLE) || '';

      try {
        const widgets = JSON.parse(sessionData);

        if (!isEmpty(sessionData)) {
          dispatch({
            type: ActionTypes.FETCH_SUGGESTIONS_DATA_SUCCESS_FROM_SESSION_MWEB,
            payload: widgets,
          });

          return Promise.resolve(sessionData);
        }
      } catch (err) {
        // Do Nothing
      }
    }

    return getMwebSearchSuggestions()
      .then((data) => {
        const suggestions = get(data, 'response.suggestions', []);
        const requiredData = Array.isArray(suggestions) ? suggestions : [suggestions];

        if (window.sessionStorage !== undefined) {
          window.sessionStorage.setItem(MWEB_SEARCH_SESSION_VARIABLE, JSON.stringify(requiredData));
        }

        dispatch({
          type: ActionTypes.FETCH_SUGGESTIONS_DATA_SUCCESS_MWEB,
          payload: requiredData,
        });

        return data;
      })
      .catch(() => {
        dispatch({ type: ActionTypes.FETCH_SUGGESTIONS_DATA_FAILED });
      });
  };
};

// for dweb only
export const callPopularOrTrendingService = (algo: string) => {
  return (dispatch: any) => {
    dispatch({ type: ActionTypes.FETCH_SUGGESTIONS_DATA_PROGRESS });

    // check if the data is available in session
    if (window.sessionStorage !== undefined) {
      const sessionData = window.sessionStorage.getItem(DWEB_SEARCH_SESSION_VARIABLE) || '';

      try {
        const payload = JSON.parse(sessionData);

        if (!isEmpty(sessionData) && payload['algo'] === algo) {
          dispatch({
            type: ActionTypes.FETCH_SUGGESTIONS_DATA_SUCCESS_FROM_SESSION_DWEB,
            payload,
          });

          return Promise.resolve(sessionData);
        }
      } catch (err) {
        // Do Nothing
      }
    }

    return getDwebSearchSuggestions(algo)
      .then((data) => {
        const requiredData = {
          message: get(data, 'response.suggestions.message', ''),
          suggestions: get(data, 'response.suggestions.results', []),
          algo: algo,
        };

        if (window.sessionStorage !== undefined) {
          window.sessionStorage.setItem(DWEB_SEARCH_SESSION_VARIABLE, JSON.stringify(requiredData));
        }

        dispatch({
          type: ActionTypes.FETCH_SUGGESTIONS_DATA_SUCCESS_DWEB,
          payload: requiredData,
        });

        return data;
      })
      .catch(() => {
        dispatch({ type: ActionTypes.FETCH_SUGGESTIONS_DATA_FAILED });
      });
  };
};

export const callAutoSuggestService = (term: string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    // dont fetch again if redux data is for same term as last fetched
    if (term === getAutoCompleteSearchTerm(state)) {
      return Promise.resolve({ suggestions: getAutoCompleteResults(state) });
    }

    if (term === '') {
      dispatch({
        type: ActionTypes.CLEAR_AUTOCOMPLETE_DATA,
      });
      return Promise.resolve({});
    }

    dispatch({
      type: ActionTypes.FETCH_AUTOCOMPLETE_DATA_PROGRESS,
      payload: term,
    });

    return getAutoSuggestData(term)
      .then((res: any) => {
        const payload = { ...get(res, 'response', {}), term };

        dispatch({
          type: ActionTypes.FETCH_AUTOCOMPLETE_DATA_SUCCESS,
          payload,
        });

        return payload;
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.FETCH_AUTOCOMPLETE_DATA_FAILED,
        });

        return Promise.reject();
      });
  };
};
