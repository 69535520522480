import { getUrl, ROUTES } from 'shared/api/urls';
import { ApiHelper } from 'shared/helpers/api';
import { getDomain } from 'shared/helpers/app';

// V3
export const getMwebSearchSuggestions = () => {
  return ApiHelper(`${getUrl(ROUTES.TRENDING_SEARCHES_V3)}`).then((data: Response) => {
    if (data.status !== 200) {
      return Promise.reject(data.statusText);
    }
    return data.json();
  });
};

// V2
export const getDwebSearchSuggestions = (algo: string): Promise<any> => {
  return ApiHelper(`${getUrl(ROUTES.TRENDING_SEARCHES)}?algo=${algo}`).then((data: Response) => {
    if (data.status !== 200) {
      return Promise.reject(data.statusText);
    }
    return data.json();
  });
};

export const getAutoSuggestData = (term: string) => {
  const url = `${getUrl(ROUTES.SEARCH_AUTO_SUGGEST)}?searchTerm=${encodeURIComponent(term)}`;
  const headers = { domain: getDomain() };
  return ApiHelper(url, { headers }).then((data: Response) => data.json());
};
