import { styled } from '@nykaa/ui-components';

export const SearchContainer = styled.div<{ showIcon: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;

  & > .icon {
    font-size: 16px;
  }

  & > .input {
    flex: 1;
    padding: 0;
    margin: ${({ theme }) => `0 0 0 ${theme.spacing.spacing40}`};
    border: 0;
    height: 30px;
    outline: none;
    margin-right: 20px;

    ${({ showIcon }) =>
      showIcon === false &&
      `
        margin-left: 0;
        `}
  }
`;
