import React, { memo, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

// styles
import { Wrapper, Message, Error, Placeholder, Shimmer } from './styles';

// components
import Suggestion from './components/Suggestion';

// constants
import { SOMETHING_WENT_WRONG, NO_RESULTS_FOUND } from './text';
import { ISuggestion, ICON_TYPE } from 'shared/store/globalSearch/types';

// helpers
import { getRecentSearches } from 'shared/components/GlobalSearch/helpers';

interface IAutoCompleteResultsProps {
  isVisible: boolean;
  searchTerm: string;
  suggestions: ISuggestion[];
  isError: boolean;
  isFetching: boolean;
  trendingSearches: string[];
  isRipple: boolean;
  maxRecentSearches: number;
  mwebSuggestions: string[];
}

function AutoCompleteResults({
  isVisible,
  searchTerm,
  suggestions,
  isError,
  isFetching,
  trendingSearches,
  mwebSuggestions,
  isRipple,
  maxRecentSearches,
}: IAutoCompleteResultsProps) {
  const searches = getRecentSearches();
  const recentSearches = useMemo(
    () => searches.map((e) => `${e.suggestionWord} ${e.suggestionSubtitle || ''}`.trim()),
    [searches]
  );

  const trendingSuggestions = isRipple ? mwebSuggestions : trendingSearches;

  const trendingSearchesInLowercase = trendingSuggestions.map((item) =>
    item.trim().toLocaleLowerCase()
  );

  const recentSearchesInLowercase = recentSearches.map((item) => item.trim().toLowerCase());

  if (!isVisible) {
    return null;
  }

  if (isFetching) {
    return (
      <Placeholder data-test="placeholderWithShimmer">
        <Shimmer />
        <Shimmer />
        <Shimmer />
        <Shimmer />
        <Shimmer />
      </Placeholder>
    );
  }

  if (isError) {
    return <Error data-test="autoSuggestResults-Error">{SOMETHING_WENT_WRONG}</Error>;
  }

  if (isEmpty(suggestions)) {
    return (
      <Message data-test="autoSuggestResults-Message">{`${NO_RESULTS_FOUND}"${searchTerm}"`}</Message>
    );
  }

  return (
    <Wrapper data-test="autoSuggestResults-container">
      {suggestions.map((suggestion: ISuggestion, idx: number) => {
        const { suggestionWord, suggestionSubtitle, url } = suggestion;
        const term = `${suggestionWord} ${suggestionSubtitle ? suggestionSubtitle : ''}`
          .trim()
          .toLocaleLowerCase();

        let icon = ICON_TYPE.SEARCH;

        if (recentSearchesInLowercase.includes(term)) {
          icon = ICON_TYPE.RECENT;
        } else if (trendingSearchesInLowercase.includes(term)) {
          icon = ICON_TYPE.TRENDING;
        }

        return (
          <Suggestion
            key={idx}
            url={url}
            clickIndex={idx + 1}
            title={suggestionWord}
            subTitle={suggestionSubtitle}
            term={searchTerm}
            type={suggestion.type || ''}
            data-test="autoSuggestResults-suggestions"
            icon={icon}
            isRipple={isRipple}
            indexValue={idx}
            maxRecentSearches={maxRecentSearches}
            suggestion={suggestion}
          />
        );
      })}
    </Wrapper>
  );
}

export default memo(AutoCompleteResults);
