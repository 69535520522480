import { createSelector } from 'reselect';
import { IGlobalSearchState } from './types';
import get from 'lodash/get';

export const search = (state: { globalSearch: IGlobalSearchState }): IGlobalSearchState =>
  state.globalSearch;

export const getPopularOrTrendingTitle = createSelector([search], (data) =>
  get(data, 'popularOrTrending.message', '')
);

export const getPopularOrTrendingResult = createSelector([search], (data) =>
  get(data, 'popularOrTrending.suggestions', [])
);

export const getWidgetData = createSelector([search], (data) => get(data, 'widgets', []));

export const getMwebWidgetSuggestions = createSelector([search], (data) => {
  const widgets = get(data, 'widgets', []);
  const mwebSuggestions = widgets.reduce((accumulator: [], widget) => {
    return accumulator.concat(widget.results);
  }, []);

  return mwebSuggestions;
});

export const isAutoCompleteFetching = createSelector([search], (data) =>
  get(data, 'isAutoCompleteFetching')
);

export const getAutoCompleteResults = createSelector([search], (data) =>
  get(data, 'autocompleteResults.suggestions', [])
);

export const getAutoCompleteSearchTerm = createSelector([search], (data) =>
  get(data, 'autocompleteResults.term', '')
);

export const getAutoCompleteResultsAPIError = createSelector(
  [search],
  (data) =>
    String(get(data, 'autocompleteResults.message')).toLocaleLowerCase() === 'success' &&
    !get(data, 'isAutoCompleteFetching') &&
    get(data, 'autocompleteResults.suggestions', []).length === 0
);

export const canDisplayAutoCompleteResults = createSelector(
  [search],
  (data) =>
    (get(data, 'autocompleteResults.suggestions', []).length >= 0 &&
      get(data, 'autocompleteResults.term', '') !== '') ||
    get(data, 'isAutoCompleteFetching')
);

export const canDisplayPopularOrTrendingWidget = createSelector(
  [search],
  (data) =>
    get(data, 'autocompleteResults.suggestions', []).length === 0 &&
    get(data, 'autocompleteResults.term', '') === '' &&
    !get(data, 'isAutoCompleteFetching')
);

export const canDisplayRecentSearchesWidgets = createSelector(
  [search],
  (data) =>
    get(data, 'autocompleteResults.suggestions', []).length === 0 &&
    get(data, 'autocompleteResults.term', '') === '' &&
    !get(data, 'isAutoCompleteFetching')
);
