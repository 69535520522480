// libs
import React, { memo, useCallback } from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import isEmpty from 'lodash/isEmpty';

// hook
import useTheme from '@nykaa/ui-components/themes/hooks/useTheme';

// constants
import { SEARCH_TYPE } from 'shared/components/GlobalSearch/constants';

// helpers
import {
  generateTrackingURLForSearch,
  trackSearchCall,
} from 'shared/components/GlobalSearch/helpers';
import { addToRecentSearch } from 'shared/components/GlobalSearch/helpers';
import { getPlatformType } from 'shared/components/AppRedirectionPopup/components/AppRedirectionPopup';
import { hexToRgb } from '@nykaa/ui-components/styles/utils';
import NFA from 'shared/helpers/analytics/NFAnalytics';

// components
import ElementWrapper from 'shared/components/GlobalSearch/components/KeyboardWrapper/ElementWrapper';

// context
import { useKeyboardEventContext } from 'shared/components/GlobalSearch/components/KeyboardWrapper/KeyboardContext';
import { useRedirectionContext } from 'shared/components/AppRedirectionPopup/Provider/context';

// styles
import {
  Term,
  SuggestionContainerMobile,
  SuggestionContainerDesktop,
  Icon,
  Image,
  DesktopTerm,
} from './styles';

// defs
import { ICON_TYPE, ISuggestion } from 'store/globalSearch/types';

// icons
import SearchRecent from '@nykaa/ui-components/Icons/search-recent';
import Search from '@nykaa/ui-components/Icons/search';
import Trending from '@nykaa/ui-components/Icons/trend-line';

const ICON_SIZE = '20px';

interface ISuggestionProps {
  term: string;
  icon: ICON_TYPE;
  isRipple: boolean;
  indexValue: number;
  maxRecentSearches: number;
  suggestion: ISuggestion;
}
const getIcon = (icon: string, theme: Record<string, Record<string, string>>) => {
  if (icon === ICON_TYPE.RECENT)
    return <SearchRecent size={ICON_SIZE} color={hexToRgb(theme?.colors?.primary, 40)} />;
  if (icon === ICON_TYPE.TRENDING)
    return <Trending size={ICON_SIZE} color={hexToRgb(theme?.colors?.primary, 40)} />;
  if (icon === ICON_TYPE.SEARCH)
    return <Search size={ICON_SIZE} color={hexToRgb(theme?.colors?.primary, 40)} />;
};
function Suggestion({
  term,
  icon,
  isRipple,
  indexValue,
  maxRecentSearches,
  suggestion,
}: ISuggestionProps) {
  const { currentIndex } = useKeyboardEventContext();
  const { showRedirectionPopup } = useRedirectionContext();
  const theme: Theme = useTheme();

  const {
    suggestionWord,
    suggestionSubtitle,
    url,
    subtext,
    type,
    image_url: imageUrl,
    id,
  } = suggestion;

  const isActive = currentIndex === indexValue;

  const goToUrl = useCallback(() => {
    const isCrossPlatformRedirect = type === 'query';

    const redirectionURL = isCrossPlatformRedirect ? id || url : url;

    addToRecentSearch(
      {
        url: redirectionURL,
        suggestionWord,
        suggestionSubtitle,
        type: SEARCH_TYPE.AUTO_COMPLETE,
      },
      maxRecentSearches
    );
    trackSearchCall({
      typedSearchTerm: suggestionSubtitle || '',
      searchTerm: suggestionWord,
      searchType: SEARCH_TYPE.AUTO_COMPLETE,
      clickPosition: indexValue,
      clickMetadata: {
        page_search_term: term,
        position: indexValue,
        q: suggestionWord,
        url: url,
        type: type,
        id: id,
      },
    });
    if (isCrossPlatformRedirect && !isEmpty(id)) {
      if (isRipple) {
        const platform = getPlatformType(id);

        const redirectToPlatform = () => (window.location.href = id);
        showRedirectionPopup({ onConfirm: redirectToPlatform, platform });
        return;
      }
      window.open(id, '_blank');
      return;
    }

    NFA.updateDatalayerBeforeNext({ suggestionPosition: indexValue + 1 });

    window.location.href = generateTrackingURLForSearch({
      url: redirectionURL,
      searchType: SEARCH_TYPE.AUTO_COMPLETE,
      internalSearchTerm: suggestionWord,
      typedSearchTerm: term,
      suggestionType: type,
      suggestionSubtitle,
    });
  }, [
    id,
    indexValue,
    isRipple,
    maxRecentSearches,
    showRedirectionPopup,
    suggestionSubtitle,
    suggestionWord,
    term,
    type,
    url,
  ]);

  const regxp = new RegExp(`(${escapeRegExp(term)})`, 'ig');
  const decoratedTitle = suggestionWord.replace(regxp, "<span class='sg-hl'>$1</span>");

  const commonProps = {
    className: 'sg-anc',
    'data-test': 'suggestion-anchor',
    onClick: goToUrl,
    'data-at': `suggestion-${indexValue}`,
  };

  let element = (
    <SuggestionContainerMobile href={url} preventDefault {...commonProps}>
      <Icon>{getIcon(icon, theme)}</Icon>
      <Term>
        <span
          data-test="suggestion-title"
          dangerouslySetInnerHTML={{
            __html: decoratedTitle,
          }}
        />
        <span className="sg-guide" data-test="suggestion-subtitle">
          {suggestionSubtitle}
        </span>
        {subtext && <div className="sg-sub-text">{subtext}</div>}
      </Term>
      {imageUrl && <Image src={imageUrl} />}
    </SuggestionContainerMobile>
  );

  if (!isRipple) {
    element = (
      <ElementWrapper onClick={goToUrl} indexValue={indexValue}>
        <SuggestionContainerDesktop {...commonProps} active={isActive}>
          <Icon>{getIcon(icon, theme)}</Icon>

          <DesktopTerm>
            <span
              data-test="suggestion-title"
              dangerouslySetInnerHTML={{
                __html: decoratedTitle,
              }}
            />
            <span className="sg-guide" data-test="suggestion-subtitle">
              {suggestionSubtitle}
            </span>
            {subtext && <div className="sg-sub-text">{subtext}</div>}
          </DesktopTerm>
          {imageUrl && <Image src={imageUrl} />}
        </SuggestionContainerDesktop>
      </ElementWrapper>
    );
  }

  return <>{element}</>;
}

export default memo(Suggestion);
