// libs
import { connect } from 'react-redux';

// components
import AutoCompleteResults from './AutoCompleteResults';

// redux store
import {
  canDisplayAutoCompleteResults,
  getAutoCompleteResults,
  getAutoCompleteSearchTerm,
  getAutoCompleteResultsAPIError,
  isAutoCompleteFetching,
  getPopularOrTrendingResult,
  getMwebWidgetSuggestions,
} from 'store/globalSearch/selectors';

const mapStateToProps = (state: any) => {
  const trendingSearches = getPopularOrTrendingResult(state).map((e) => e.suggestionWord);
  const mwebSuggestions = getMwebWidgetSuggestions(state).map((e) => e.suggestionWord);

  return {
    isVisible: canDisplayAutoCompleteResults(state),
    suggestions: getAutoCompleteResults(state),
    searchTerm: getAutoCompleteSearchTerm(state),
    isError: getAutoCompleteResultsAPIError(state),
    isFetching: isAutoCompleteFetching(state),
    trendingSearches,
    mwebSuggestions,
  };
};

export default connect(mapStateToProps)(AutoCompleteResults);
