// lib
import React, { memo, useEffect } from 'react';

// context
import { useKeyboardEventContext } from './KeyboardContext';

// types
import { ISuggestion } from 'shared/store/globalSearch/types';

interface IElementWrapperProps {
  children: React.ReactNode;
  onClick: (searchInfo: ISuggestion) => void;
  indexValue: number;
}

function ElementWrapper({ onClick, children, indexValue }: IElementWrapperProps) {
  const { triggerSearch, currentIndex, list, setCurrentIndex } = useKeyboardEventContext();
  const isActive = currentIndex === indexValue;

  useEffect(() => {
    if (triggerSearch && isActive) {
      const triggerSearchInfo = list[currentIndex];
      onClick(triggerSearchInfo);
    }
  }, [triggerSearch, currentIndex, list, onClick, isActive]);

  const handleMouseOver = (e: React.MouseEvent) => {
    e.preventDefault();
    indexValue !== -1 && setCurrentIndex(indexValue);
  };

  const handleMouseOut = (e: React.MouseEvent) => {
    e.preventDefault();
    indexValue !== -1 && setCurrentIndex(-1);
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        role="button"
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
        aria-hidden={true}
      >
        {children}
      </div>
    </>
  );
}

export default memo(ElementWrapper);
