// libs
import React, {
  memo,
  forwardRef,
  useImperativeHandle,
  useState,
  RefForwardingComponent,
  useEffect,
  useRef,
} from 'react';

// icons
import SearchIcon from '@nykaa/ui-components/Icons/search';

// styles
import { SearchContainer } from './styles';

// hook
import useTheme from '@nykaa/ui-components/themes/hooks/useTheme';

// helpers
import NFA from 'shared/helpers/analytics/NFAnalytics';
import { getDeviceType } from 'shared/helpers/app';

// constants
import { SEARCH_EVENTS } from 'shared/helpers/analytics/constants/search';

// type
import { Theme } from '@nykaa/ui-components/themes/types';

// defs
interface ISearchProps {
  onChange?: (text: string) => void;
  onSubmit?: (text: string) => void;
  showIcon?: boolean;
  placeHolder?: string;
  autoFocus?: boolean;
  className?: string;
  initialValue?: string;
  onFocus?: () => void;
}

export interface ISearchInputRef {
  setValue: (value: string) => void;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

const SEARCH = 'Search';

const SEARCH_ICON_SIZE = '16px';

const SearchInput: RefForwardingComponent<ISearchInputRef, ISearchProps> = (
  {
    onChange = EMPTY_FUNC,
    onSubmit = EMPTY_FUNC,
    placeHolder = '',
    showIcon = true,
    autoFocus = false,
    className = '',
    initialValue = '',
    onFocus = EMPTY_FUNC,
  }: ISearchProps,
  ref
) => {
  const [inputValue, updateInputValue] = useState<string>(initialValue);
  const inpRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    autoFocus && inpRef.current !== null && inpRef.current.focus();
  }, [autoFocus]);

  const theme: Theme = useTheme();

  const setInputValue = (value: string) => {
    updateInputValue(value);
    onChange(value);
  };

  useImperativeHandle(ref, () => ({
    setValue: (value: string) => setInputValue(value),
  }));

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    setInputValue(event.target.value);

  const onInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toUpperCase() === 'ENTER') {
      onSubmit(inputValue);
    }
  };

  const trackSearchClick = () => {
    if (getDeviceType() === 'WEBSITE') {
      NFA.track({
        event: SEARCH_EVENTS.SEARCH_INPUT_CLICKED,
      });
    }
  };

  return (
    <SearchContainer
      data-test="search-input-container"
      showIcon={showIcon}
      className={className}
      onClick={trackSearchClick}
    >
      {showIcon && (
        <SearchIcon
          size={SEARCH_ICON_SIZE}
          data-test="search-icon"
          color={theme.colors.textDisabled}
        />
      )}
      <input
        type="text"
        ref={inpRef}
        aria-label={SEARCH}
        onChange={onInputChange}
        value={inputValue}
        onKeyPress={onInputKeyPress}
        placeholder={placeHolder}
        className="input"
        onFocus={onFocus}
        data-test="search-input"
        data-at="search-input"
      />
    </SearchContainer>
  );
};

export default memo(forwardRef(SearchInput));
