import { FILTER_KEYS, SORT_FILTERS } from 'shared/layouts/Listing/constants';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import escape from 'lodash/escape';
import {
  FILTER_SEPARATOR,
  OBJECT_JOIN_CHAR,
  PRICE_JOIN_CHAR,
  OBJECT_JOIN_CHAR_ENCRYPT,
  IS_FILTERED_URL,
} from './constants';

const PRICE_JOIN_CHAR_ENCRYPT = '`$`';
const FILTER_ASSIGNMENT_CHAR = '=';

const QS_PAGE = 'p';
const QS_FILTERS = 'f';
const QS_SEARCHTERM = 'q';
const SORT_FILTER = 'product_list_order';

const OBJECT_JOIN_REGEXP = new RegExp(OBJECT_JOIN_CHAR, 'g');
const PRICE_JOIN_REGEXP = new RegExp(PRICE_JOIN_CHAR, 'g');

const OBJECT_JOIN_ENCRYPT_REGEXP = new RegExp(OBJECT_JOIN_CHAR_ENCRYPT, 'g');
const PRICE_JOIN_ENCRYPT_REGEXP = new RegExp(PRICE_JOIN_CHAR_ENCRYPT, 'g');

// defs
interface IShouldFetchAPIProps {
  urlId: string;
  urlSearchTerm: string;
  urlFilters: Listing.IAppliedFilters;
  stateId: string;
  stateSearchTerm: string;
  stateFilters: Listing.IAppliedFilters;
  isSearch: boolean;
}

export const decodeURI = (search: string) => {
  const searchParams = new URLSearchParams(search);

  let page = 1;
  let appliedFilters: Listing.IAppliedFilters = {};
  searchParams.forEach((unSafeValue, key) => {
    const value = escape(unSafeValue);
    switch (key.toLowerCase()) {
      case QS_PAGE: {
        page = Number.isNaN(Number(value)) ? 1 : Number(value);
        return;
      }

      case SORT_FILTER: {
        appliedFilters['sort'] = value;
        return;
      }

      case QS_FILTERS: {
        value.split(FILTER_SEPARATOR).map((qsFilter) => {
          try {
            const [filterKey, filterValue] = qsFilter.split(FILTER_ASSIGNMENT_CHAR);

            if (filterValue.indexOf(OBJECT_JOIN_CHAR) >= 0) {
              appliedFilters[filterKey] = filterValue
                .split(OBJECT_JOIN_CHAR)
                .filter(
                  (tmpValue) => tmpValue !== undefined && isEmpty(String(tmpValue)) === false
                );
            } else if (filterValue.indexOf(PRICE_JOIN_CHAR) >= 0) {
              const [min, max] = filterValue.split(PRICE_JOIN_CHAR);

              if (Number(min) && Number(max)) {
                appliedFilters[filterKey] = { min: Number(min), max: Number(max) };
              }
            } else {
              appliedFilters[filterKey] = filterValue
                .replace(OBJECT_JOIN_ENCRYPT_REGEXP, OBJECT_JOIN_CHAR)
                .replace(PRICE_JOIN_ENCRYPT_REGEXP, PRICE_JOIN_CHAR);
            }
          } catch (e) {
            /** DO NOTHING */
          }
        });
        return;
      }
    }
  });

  return { page, filters: appliedFilters };
};

export const encodeURI = (
  page: number = 1,
  filters: Listing.IAppliedFilters = {},
  existingSearch = ''
) => {
  const searchParams = new URLSearchParams(existingSearch);

  if (searchParams.has(QS_PAGE)) {
    searchParams.delete(QS_PAGE);
  }

  if (searchParams.has(QS_FILTERS)) {
    searchParams.delete(QS_FILTERS);
  }

  if (page > 1) {
    searchParams.append(QS_PAGE, String(page));
  }

  // this param needs to be deleted once the user
  // 1> has applied filters
  // 2> has scrolled down to page 2 and beyond for the same category listing
  if (searchParams.has(IS_FILTERED_URL) && page === 1) {
    searchParams.delete(IS_FILTERED_URL);
  }

  const queryStringFilters = Object.keys(filters)
    .filter((filterKey) => {
      if (FILTER_KEYS.SORT === filterKey && filters[filterKey] !== SORT_FILTERS[0].value) {
        return true;
      }

      if (FILTER_KEYS.PAGE_SIZE !== filterKey && FILTER_KEYS.SORT !== filterKey) {
        return true;
      }

      return false;
    })
    .map((filterKey) => {
      const value = filters[filterKey];

      if (isEmpty(value)) {
        return;
      }

      let qsValue = String(value);

      if (Array.isArray(value)) {
        qsValue = value.join(OBJECT_JOIN_CHAR).concat(OBJECT_JOIN_CHAR);
      } else if (typeof value === 'object') {
        qsValue = `${value.min}${PRICE_JOIN_CHAR}${value.max}`;
      } else {
        qsValue = qsValue
          .replace(OBJECT_JOIN_REGEXP, OBJECT_JOIN_CHAR_ENCRYPT)
          .replace(PRICE_JOIN_REGEXP, PRICE_JOIN_CHAR_ENCRYPT);
      }

      return `${filterKey}${FILTER_ASSIGNMENT_CHAR}${qsValue}`;
    })
    .join(FILTER_SEPARATOR);

  if (isEmpty(queryStringFilters) === false) {
    searchParams.append(QS_FILTERS, queryStringFilters);
  }

  return searchParams.toString();
};

export const getURLSearchTerm = (existingSearch = ''): string => {
  const searchParams = new URLSearchParams(existingSearch);

  if (searchParams.has(QS_SEARCHTERM)) {
    // @ts-ignore
    return escape(searchParams.get(QS_SEARCHTERM));
  }

  return '';
};

export const shouldFetchAPI = ({
  urlId,
  urlSearchTerm,
  urlFilters,
  stateId,
  stateSearchTerm,
  stateFilters,
  isSearch,
}: IShouldFetchAPIProps) => {
  // If Route Listing check for category ID mismatch
  if (isSearch === false && urlId !== stateId) {
    return true;
  }

  // If Route Search check for search term mismatch
  if (isSearch && urlSearchTerm !== stateSearchTerm) {
    return true;
  }

  if (isEqual(urlFilters, stateFilters) === false) {
    return true;
  }

  return false;
};
