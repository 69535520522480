import { styled } from '@nykaa/ui-components';
import { truncate } from 'shared/styles/mixin/core';
import RippleIt from 'shared/components/RippleIt';

const IMAGE_DIMENSION = 40;

export const Term = styled.span`
  width: calc(80% - ${IMAGE_DIMENSION}px);
  ${truncate};
`;

export const DesktopTerm = styled(Term)`
  margin-left: ${({ theme }) => theme.spacing.spacing20};
`;

export const ContainerMixin = styled.a`
  display: block;
  min-height: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
  ${({ theme }) => theme.typography.bodyMedium}
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};

  & .sg-hl {
    ${({ theme }) => theme.typography.subTitleMedium};
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  & .sg-guide {
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-left: ${({ theme }) => theme.spacing.spacing20};
  }

  & .sg-sub-text {
    color: ${({ theme }) => theme.colors.textSecondary};
    ${({ theme }) => theme.typography.bodySmall};
    margin-top: ${({ theme }) => theme.spacing.spacing10};
  }
`;

export const SuggestionContainerMobile = styled(ContainerMixin.withComponent(RippleIt))`
  padding: ${({ theme }) =>
    `${theme.spacing.spacing60} ${theme.spacing.spacing100} ${theme.spacing.spacing60} ${theme.spacing.spacing100}`};
`;

export const SuggestionContainerDesktop = styled(ContainerMixin)<{ active: boolean }>`
  ${({ active, theme }) => active && `background-color: ${theme.colors.surface20}`};
  padding: ${({ theme }) =>
    `${theme.spacing.spacing40} ${theme.spacing.spacing40} ${theme.spacing.spacing40} ${theme.spacing.spacing80}`};
  cursor: pointer;
`;

export const Icon = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.spacing40};
  & path {
    fill: ${({ theme }) => theme.colors.textSecondary};
  }
`;

export const Image = styled.img`
  height: 48px;
  width: 40px;
  margin-left: auto;
`;
