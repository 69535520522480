import { styled } from '@nykaa/ui-components';
import { Shimmer as BaseShimmer } from 'shared/styles/mixin';

export const Wrapper = styled.div`
  overflow-y: auto;
  height: calc(100% - 56px);
`;

export const Message = styled.div`
  margin-top: ${({ theme }) => theme.spacing.spacing50};
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.typography.bodyLarge};
  text-align: center;
  max-width: 100%;
  word-break: break-word;
`;

export const Error = styled(Message)`
  color: #ff0000;
`;

export const Placeholder = styled.div`
  padding: ${({ theme }) => theme.spacing.spacing80};
`;

export const Shimmer = styled(BaseShimmer)`
  height: 24px;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.spacing80};
`;
